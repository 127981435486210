import "focus-visible";
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "components/Layout";
import Title from "components/Title";
import "pages/404.scss";


// markup
export default function NotFoundPage() {
	return (
		<Layout title="404 Not Found">
			<Title
				title="Sorry. Content was not found!"
				content="We can't find the content you are looking for. Please try something else."
			></Title>
			<div className="content-404">
				<p>
					<Link to="/" className="ecl-button ecl-button--call">Back to home</Link>
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;